import Social from 'components/global/social'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import ScrollIcon from 'svgs/scroll.svg'

import DesktopVideo from '../../images/videos/414x600.mp4.mp4'
import MobileVideo from '../../images/videos/1920x800.mp4.mp4'

const IndexHeaderStyles = styled.header`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 4.5rem;
  .mobile {
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: auto;
    }
    @media ${media.md} {
      display: none;
    }
  }
  .desktop {
    display: none;
    @media ${media.md} {
      display: block;
      /* max-width: 1440px; */
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        max-height: 800px;
      }
    }
  }
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      width: 100%;
      display: none;
      max-width: 1000px;
      height: 90%;
      max-height: 800px;
      margin: var(--auto);
    }
  }
  .scroll {
    overflow: visible;
    position: absolute;
    bottom: 7rem;
    left: calc(0% + 45%);
    @media ${media.md} {
      left: calc(0% + 49%);
      bottom: 2rem;
    }
    fill: var(--white);
    width: 30px;
    height: 30px;
    #scroll-1 {
      animation: fade-in-top 2.5s 0.25s cubic-bezier(0.39, 0.575, 0.565, 1)
        infinite;
    }
    #scroll-2 {
      animation: fade-in-top 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
    }
  }
  /* video {
    display: none;
  } */
  @media ${media.md} {
    margin-top: -1rem;
  }
`
const IndexHeader = props => {
  const { data } = useStaticQuery(graphql`
    query {
      mobile: file(name: { eq: "mt-desktop-bkg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(true)
  const background = data

  return (
    <IndexHeaderStyles {...props}>
      <section>
        <h1 className="sr-only">The Mousetrap</h1>
        <div className="mobile">
          <video playsInline muted autoPlay className="vid-mobile">
            <source
              src={DesktopVideo}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="desktop">
          <video playsInline muted autoPlay className="vid-desktop">
            <source
              src={MobileVideo}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="img-overlay">
          <StaticImage
            src="../../images/backgrounds/mt-desktop-bkg.jpeg"
            alt="The Mousetrap"
          />
        </div>
        <ScrollIcon className="scroll" />
      </section>
      {/* <Social $tabletND $cropped /> */}
    </IndexHeaderStyles>
  )
}

export default IndexHeader
