import React from 'react'
import MaxWidth from 'components/functional/maxWidth'
import styled from 'styled-components'

import { media } from 'utils/Media'

const VideoStyles = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  @media ${media.md} {
    max-width: 1000px;
    margin: 0 auto !important;
    text-align: center;
    padding: 2rem 0;
  }
  iframe {
    border-radius: 5px;
    box-shadow: 0 0 10px 10px #8c290d;
    border: solid 2px var(--mainYellow);
    width: 100%;
    height: auto;
    margin: 0 auto !important;
    height: 17rem;
    @media ${media.sm} {
      height: 22rem;
    }
    @media ${media.md} {
      height: 28rem;
      width: 750px;
    }
  }
`

const embedId = 'o9Auk97FL7U'
const Video = () => (
  <MaxWidth>
    <VideoStyles>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </VideoStyles>
  </MaxWidth>
)

export default Video
