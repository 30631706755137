import CovidUpdate from 'components/global/covidUpdate'
import EmailSignup from 'components/global/emailSignup'
import Layout from 'components/global/layout'
import Buttons from 'components/index/buttons'
import GallerySlider from 'components/index/gallerySlider'
import IndexHeader from 'components/index/headerCropped'
import Intro from 'components/index/intro'
import Cartoon from 'components/index/cartoon'
import Reviews from 'components/index/reviews'
import Video from 'components/index/video'
import {graphql} from 'gatsby'
import React from 'react'

const meta = {
    title:
        'The Mousetrap Official Site - The world’s longest running play in the West End.',
    description:
        'The world’s longest running play is back onstage now in the West End. Performances now on sale until 2025! – book direct and book with confidence with no quibble exchanges.',
}

const IndexPage = ({data}) => {
    const images = data.Images.nodes
    const review = data.Review.nodes
    return (
        <Layout title={meta.title} page="home" description={meta.description}>
            <IndexHeader/>
            <Intro/>
            {/* <CovidUpdate /> */}
            <Buttons/>
            <Video/>
            <GallerySlider data={images}/>
            <Reviews data={review}/>
            <Cartoon/>
            <EmailSignup/>
        </Layout>
    )
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    Images: allSliderJson(limit: 5) {
      nodes {
        image {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    Review: allReviewsJson {
      nodes {
        review
        reviewer
      }
    }
  }
`
