import React, { useRef } from 'react'
import styled from 'styled-components'
import MainBackground from 'images/backgrounds/wooden-background.jpeg'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import Arrow from 'svgs/arrow.svg'
import SingleReview from 'components/shared/singleReview'
import { media } from 'utils/Media'

const ReviewsStyles = styled.section`
  width: 100%;
  /* background-image: url(${props => props.img});
  background-size: cover;
  background-position: center; */
  position: relative;
  z-index: 2;
  padding: 2.5rem 0;

  @media ${media.md} {
    padding: 1.5rem 0;
  }
  &:before {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: 5rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
    
  }
  &:after {
    display:  none;
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 5rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
    @media ${media.md} {
      top: 1rem;
      display: none;
    }
  }
  > div {
    width: 90%;
    margin: var(--auto);
    h3 {
      text-align: center;
      font-size: 2rem;
      @media ${media.md} {
        font-size: 2.35rem;
      }
    }
    .slick-slider {
      .slick-dots {
        margin-top: -2rem;
        position: relative;
        z-index: 2;
        .slick-active {
          button {
            background-color: var(--black);
            border: solid 1px var(--white);
          }
        }
        li button {
          background-color: white;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          &::before {
            color: transparent;
          }
        }
      }
    }
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: auto;
    }
    .buttons {
      margin-top: 3rem;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    @media ${media.s} {
      padding-bottom: 2rem;
    }
    @media ${media.md} {
      width: 80%;
      max-width: 850px;
      .buttons {
        margin-top: 5rem;
      }
    }
  }
`
const Reviews = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 765,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const sliderRef = useRef()
  const next = () => {
    sliderRef.current.slickNext()
  }
  const prev = () => {
    sliderRef.current.slickPrev()
  }
  return (
    <ReviewsStyles img={MainBackground}>
      <div>
        <h3>NEWS &amp; REVIEWS</h3>
        <Slider {...settings} ref={sliderRef}>
          {data.map((i, x) => (
            <SingleReview key={x} data={i} />
          ))}
        </Slider>
        <ButtonToggles next={next} prev={prev} />
        <div className="buttons">
          <Link to="/news-and-reviews/">
            <button type="button" className="btn">
              <span>READ MORE</span>
            </button>
          </Link>
        </div>
      </div>
    </ReviewsStyles>
  )
}

export default Reviews

const ButtonTogglesStyles = styled.div`
  width: 100%;
  margin-top: -2rem;
  position: relative;
  z-index: 10;
  .button-toggle {
    width: 100%;
    margin: var(--auto);
    display: flex;
    justify-content: space-between;
    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      &:nth-child(2) {
        .icon {
          transform: rotate(180deg);
        }
      }
      .icon {
        width: 35px;
        height: 35px;
        margin-top: 25px;
        &:nth-child(2) {
          transform: rotate(180deg);
        }
      }
    }
  }
  @media ${media.md} {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    /* left: 0;
    top: 20rem; */
    .button-toggle {
      width: 80%;
    }
  }
  @media ${media.lg} {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 15rem;
    .button-toggle {
      width: 80%;
      max-width: 1000px;
    }
  }
`

const ButtonToggles = ({ next, prev }) => (
  <ButtonTogglesStyles>
    <div className="button-toggle">
      <button type="button" onClick={() => prev()}>
        <Arrow className="icon" />
      </button>
      <button type="button" onClick={() => next()}>
        <Arrow className="icon" />
      </button>
    </div>
  </ButtonTogglesStyles>
)
