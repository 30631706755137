import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const SingleReviewStyles = styled.div`
  width: 90%;
  margin: 2rem auto;
  text-align: center;
  max-width: 1050px;
  background: #000;
  border-radius: 5px;
  box-shadow: 0 0 10px 10px #8c290d;
  border: solid 2px var(--mainYellow);
  padding: 2rem;
  strong {
    color: var(--mainYellow);
    font-weight: 400;
    font-style: italic;
    font-size: 1.2rem;
    @media ${media.md} {
      font-size: 1.5rem;
    }
  }
  p {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  @media ${media.lg} {
    height: 310px;
  }
`
const SingleReview = ({ data }) => (
  <SingleReviewStyles>
    <strong>{`"${data.review}"`}</strong>
    <p>{`${data.reviewer}`}</p>
  </SingleReviewStyles>
)

export default SingleReview
