import * as React from "react"
import {graphql, StaticQuery} from "gatsby";
import styled from "styled-components"
import {media} from "utils/Media"
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 0 3rem;
  max-width: 1200px;
  margin: 0 auto;

  @media ${media.md} {
    display: flex;
    justify-content: center;
  }
  
  .image {
    padding: 2rem 2rem;

    @media ${media.md} {
      width: calc(50% - 3rem);
      padding: 0 1.5rem;
    }
  }
`

const Cartoon = (props) => (
    <Wrapper>
        <div className="image image1">
            <GatsbyImage image={getImage(props.data.cartoon1)}
                         alt=""
                         title=""
                         className="img-fluid"
            />
        </div>
        <div className="image image2">
            <GatsbyImage image={getImage(props.data.cartoon2)}
                         alt=""
                         title=""
                         className="img-fluid"
            />
        </div>
    </Wrapper>
)

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                cartoon1: file(relativePath: { eq: "70thcartooon-1.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 700, quality: 100, placeholder: NONE)
                    }
                }
                cartoon2: file(relativePath: { eq: "70thcartooon-2.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 700, quality: 100, placeholder: NONE)
                    }
                }
            }
		`}
        render={data => (
            <Cartoon data={data} {...props}/>
        )}
    />
)

export default Query